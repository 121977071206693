export const useUtm = () => {

  const storage = useSession();
  const route = useRoute();

  const getUrlWithUtmParameters = () => {
    if (!process.client) return;

    const utmData = storage.getItem('vhs_utm');
    const utmQuery = utmData ? (new URLSearchParams(utmData)).toString() : "";
    const querySign = window.location.search ? "&" : "?";
    return window.location.href + (utmQuery ? querySign + utmQuery : "");
  }

  const getQueryUtmParameters = () => {
    if (!process.client) return '';
  
    const utmData = storage.getItem('vhs_utm');
    if (!utmData) return '';
  
    const utmQuery = new URLSearchParams(utmData).toString();
    const querySign = window.location.search ? "&" : "?";
  
    const finalQuery = utmQuery ? `${querySign}${utmQuery}` : '';
    return finalQuery;
  };
  

  const getUtmData = () => {
    return storage.getItem('vhs_utm');
  }

  const setUtm = () => {
    const utmParameters = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_id',
      'uap',
      'saids',
      'cmids',
      'utm_term',
      'utm_content',
      'gclid',
      'fbclid',
      'gad_source',
      'click_id',
      'adfcd',
      'tblci',
      'at_gd'
    ];
    let params = {};

    for (let item in route.query) {
      if (utmParameters.includes(item)) {
        params[item] = route.query[item]
      }
    }

    if (Object.keys(params).length) {
      storage.setItem('vhs_utm', params);
    }
    return params;
  }

  return {
    setUtm,
    getUrlWithUtmParameters,
    getQueryUtmParameters,
    getUtmData
  }
}

export const useBackendStore = () => {
  const route = useRoute();
  const brands = useState('brandsStore', () => []);
  const bodies = useState('bodiesStore', () => []);
  const gearboxes = useState('gearboxesStore', () => []);
  const drives = useState('drivesStore', () => []);
  const purposes = useState('purposesStore', () => []);
  const setup = useState('setupStore', () => []);

  function setBrands(newBrands) {
    brands.value = newBrands
  }

  function setBodies(newBodies) {
    bodies.value = newBodies
  }

  function setPurposes(newPurposes) {
    purposes.value = newPurposes
  }

  function setSetup(newSetup) {
    setup.value = newSetup;
  }

  function setGearboxes(newGearboxes) {
    gearboxes.value = newGearboxes
  }

  function setDrives(newDrives) {
    drives.value = newDrives
  }

  return {
    brands,
    setBrands,
    bodies,
    setBodies,
    purposes,
    setPurposes,
    setup,
    setSetup,
    drives,
    setDrives,
    gearboxes,
    setGearboxes
  };
}

export default defineNuxtPlugin((nuxtApp) => {

  const sticky = {
    beforeMount: async (el, options) => {
      if(!process.client) return;
       window.addEventListener('scroll', (e) => {
         if(window.scrollY > 100) {
           el.style.position="fixed";
         } else {
           el.style.position="static";
         }
       })
    },
    unmounted: () => {

    }
  };

  nuxtApp.vueApp.directive('sticky', sticky)
});

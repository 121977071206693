export const useLeasingParams = () => {

  const {setGlobal, getGlobal} = useGlobals();
  const route = useRoute();

  const defaultLeasingParams = {
    client: "consumer",
    duration: 60,
    initialFee: 25,
    repurchase: 35
  }

  const leasingDrawerOpened = useState('leasingDrawerOpened', () => !!route.meta?.openLeasingParams);

  const cookieData = useCookie('vhs_f');
  const storedData = useState('vhs_f', () => ({...(cookieData.value || defaultLeasingParams)}));

  const maxRepurchase = {
    '36': 50,
    '48': 40,
    '60': 35
  }

  const leasingParamsDefinition = {
    client: {
      entrepreneur: 'Dla firmy',
      consumer: 'Prywatnie'
    },
    duration: [36, 48, 60]
  };

  const getCurrentParams = () => {
    return Object.assign({...defaultLeasingParams},{...storedData.value});
  }

  const setCurrentParams = (params) => {
    const leasingParams = {...toRaw(storedData.value)}
    const keys = Object.keys(defaultLeasingParams);

    const newParams = {};

    keys.forEach(key => {
      newParams[key] = params[key] || leasingParams[key]
      if (key !== 'client') {
        newParams[key] = parseInt(newParams[key])
      }
    });

    storedData.value = newParams;
    cookieData.value = newParams;
    nextTick(() => executeCallbacks());
  }

  const refParams = computed(() => storedData.value);

  const onChange = (callback) => {
    const callbacks = getGlobal('leasingParamsCallbacks', []);
    callbacks.push(callback);
    setGlobal('leasingParamsCallbacks', callbacks);
  }

  const executeCallbacks = () => {
    const callbacks = getGlobal('leasingParamsCallbacks', []);
    callbacks.forEach(callback => callback());
  }

  return {
    leasingDrawerOpened,
    leasingParamsDefinition,
    maxRepurchase,
    defaultLeasingParams,
    getCurrentParams,
    setCurrentParams,
    onChange,
    refParams
  }
}
